<template>
  <AtomsGlobalDialog :is-open="dialog?.isOpen" :is-message-container="dialog?.isMessageContainer" @close="close" class="country-redirect-suggestion-dialog">
    
    <!-- heading -->

    <strong class="block mb-8 pb-8 uppercase font-bold text-2xl border-b border-neutral-200 text-error-500 tablet:px-10">{{ currentCopy.title }}</strong>

    <div class="desktop:px-10">{{ currentCopy.message }}</div>

    <!-- buttons -->

    <div class="flex flex-wrap gap-4 justify-between mt-8 pt-8 border-t border-neutral-200 desktop:px-10">
      <MoleculesButtonsButton :arrow-left="true" :ghost="true" class="order-1 w-full tablet:order-0 tablet:w-auto" @click="close">{{ currentCopy.buttonStay }}</MoleculesButtonsButton>
      <MoleculesButtonsButton class="order-0 w-full tablet:order-1 tablet:w-auto" @click="redirect">
        <div class="flex items-center justify-center gap-4">
          <img class="country-redirect-suggestion-dialog__flag" :src="`/img/flags/${targetShopZone.locale}.svg`" :alt="`${targetShopZone.locale} flag`" />
          {{ currentCopy.buttonRedirect }}
        </div>
      </MoleculesButtonsButton>
    </div>
  </AtomsGlobalDialog>
</template>
<script setup>

const appConfig = useAppConfig();
const route = useRoute();

const dialog = computed(() => useDialog().get('countryRedirectSuggestion'));

const close = () => {

  useCookie('suggestionRedirectDeclined').value = 'true';

  useBaseAnalytics().pushEvent('user_interaction', {
    interaction_name: `country_redirect_suggestion_modal_close_${targetShopZone.value.locale}`
  });

  useDialog().close();
}

const redirect = () => {
  useBaseAnalytics().pushEvent('user_interaction', {
    interaction_name: `country_redirect_suggestion_click_${targetShopZone.value.locale}`
  }, () => {

    let alternativePageUrl = cleanUrl(usePage().value.page?.alternativeUrls?.find(z => z.shopZone.id === targetShopZone.value.shopZoneId)?.url);

    if (alternativePageUrl) {
      if (Object.entries(route.query).length) {
        alternativePageUrl += '?' + Object.entries(route.query)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');
      }

      alternativePageUrl += route.hash;
    }
    else {
      alternativePageUrl = '';
    }

    const redirectUrl = `https://${getDomain(targetShopZone.value)}${alternativePageUrl}`;

    window.location.href = redirectUrl;
  });
};

const getDomain = (item) => {
  if (useState('isDev').value) {
    return item.devDomain;
  }
  else {
    return item.domain;
  }
}

const cleanUrl = (dirtyUrl) => {
  if (dirtyUrl && dirtyUrl.endsWith('/home')) {
    return dirtyUrl.substring(0, dirtyUrl.length - 5);
  }

  return dirtyUrl;
}

const copyByCountryId = {
  1: {
    title: 'Navštivte náš český e-shop',
    message: 'Vypadá to, že jste z České republiky. Pro lepší zážitek a zobracení cen v českých korunách navštivte náš český e-shop.',
    buttonStay: 'Zůstanu tady',
    buttonRedirect: 'Přejít na Rigad.cz',
    locale: 'cs',
    shopZoneId: 9
  },
  2: {
    title: 'Navštívte náš slovenský e-shop',
    message: 'Vyzerá to, že ste zo Slovenska. Pre lepší zážitok a zobrazenie cien v eurách navštívte náš slovenský e-shop.',
    buttonStay: 'Zostanem tu',
    buttonRedirect: 'Prejsť na Rigad.sk',
    locale: 'sk',
    shopZoneId: 11
  },
  other: {
    title: 'Visit our english e-shop',
    message: 'For a better experience and to view prices in euros or dollars, please visit our english e-shop.',
    buttonStay: 'I will stay here',
    buttonRedirect: 'Go to Rigad.com',
    locale: 'en',
    shopZoneId: 13
  },
}

const currentCopy = computed(() => {
  return copyByCountryId[dialog.value?.data?.countryId] || copyByCountryId.other;
});

const targetShopZone = computed(() => {
  return appConfig.shopZones.find(shopZone => shopZone.shopZoneId === currentCopy.value.shopZoneId);
});

</script>
<style lang="postcss">
.country-redirect-suggestion-dialog {

  @apply w-[700px] desktop:w-[950px];

  &__flag {
    @apply w-6 rounded-[4px] transition-all;
  }
}
</style>